import React, {Component} from 'react';
import NewWindow from 'react-new-window'

class Subview extends Component
{
  constructor(props) {
    super(props);
  }

  render()
  {
    return <NewWindow url={this.props.url}
    center="parent"
    features="width=1920,height=1080"
    onUnload={this.props.onClose}></NewWindow>;
  }
}

export default Subview;
