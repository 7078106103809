import React, { Component } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react'

import Main from '../pages/Main'
import Classroom from '../pages/Classroom'

class MainTransition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMainView: (localStorage.getItem("roomType") === "single"),
      isClassroomView: (localStorage.getItem("roomType") === "shared"),
    };
  }

  continueToMainView = () => {
    this.setState({ isPrevSessionActive: true });
    this.setState({ isMainView: true });
    localStorage.setItem("roomType", "single");
  }

  continueToClassroomView = () => {
    this.setState({ isPrevSessionActive: true });
    this.setState({ isClassroomView: true });
    localStorage.setItem("roomType", "shared");
  }

  componentDidMount(){
    this.continueToMainView();
  }

  render() {
    let element = <div style={{ height: '100%' }}>
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450, marginTop: 15 }}>
          <Header as='h2' color='blue' textAlign='center'>ACT instance ready.</Header>
          <Header as='h4' color='grey' textAlign='center'>Please choose between private or classroom session</Header>
          <Button.Group size='big'>
            <Button onClick={this.continueToMainView}>Private session</Button>
            <Button.Or />
            <Button onClick={this.continueToClassroomView}>Start classroom</Button>
          </Button.Group>
        </Grid.Column>
      </Grid>
    </div>;

    if(this.state.isClassroomView)
      element = <Classroom onLogout={this.props.onLogout}/>;
    else if(this.state.isMainView)
      element = <Main onLogout={this.props.onLogout}/>;


    return (<>{element}</>);
  }
}

export default MainTransition;
