import React, { Component } from 'react';
import LoginHandler from "./pages/LoginHandler"
import WsContext from './components/WebSocketContext'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from "./components/PrivateRoute"
import API from './components/API'
import NotFound from './pages/NotFound'
import Wsc from './components/WebSocketClient'
import Portal from './components/MessagePortal'
import Main from './pages/Main'
import Impress from './pages/Impress';
import PresentationHost from './pages/PresentationHost'
import PresentationClient from './pages/PresentationClient'

import './css/App.css'
import 'semantic-ui-css/semantic.min.css';

const { detect } = require('detect-browser');
const browser = detect();

class App extends Component {
  state = {
    isLoggedIn: (localStorage.getItem("sessionId") !== null),
    isSupportedBrowser: false
  }

  componentDidMount() {
    if (browser) {
      var split = browser.version.split('.');
      
      console.log(browser.name);
      console.log(browser.version); 
      console.log(browser.os);

      if (browser.name === "firefox") {
        if (split.length > 0) {
          if (parseInt(split[0]) > 65)
            this.setState({ isSupportedBrowser: true });
        }
      }
      else if (browser.name === "chrome") {
        if (split.length > 0) {
          if (parseInt(split[0]) > 70)
            this.setState({ isSupportedBrowser: true });
        }
      }
      else if (browser.name === "safari") {
        if (split.length > 0) {
          if (parseInt(split[0]) > 13)
            this.setState({ isSupportedBrowser: true });
        }
      }
      else if (browser.name === "crios") {
        if (split.length > 0) {
          if (parseInt(split[0]) > 90)
            this.setState({ isSupportedBrowser: true });
        }
      }
    }

    // if (this.state.isLoggedIn)
    //   this.webSock.connect(this.api.getWebsockAddr() + "/" + localStorage.getItem("sessionId"));
  }

  login = () => {
    this.webSock.connect(this.api.getWebsockAddr() + "/" + localStorage.getItem("sessionId"));
    this.setState({ isLoggedIn: true });
  }

  logout = () => {
    this.webSock.shutdown();
    this.setState({ isLoggedIn: false });
  }

  api = new API();
  webSock = new Wsc();

  render() {
    const { isSupportedBrowser } = this.state
    return (<div>
      {isSupportedBrowser ?
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path="/" isAuthenticated={this.state.isLoggedIn} render={(props) =>
              <WsContext.Provider value={this.webSock}>
                <LoginHandler {...props} onLoginSuccess={this.login} onLogout={this.logout} isFair={true} />
              </WsContext.Provider>} />
            <PrivateRoute path="/app" isAuthenticated={this.state.isLoggedIn} render={(props) =>
              <WsContext.Provider value={this.webSock}>
                <LoginHandler {...props} onLoginSuccess={this.login} onLogout={this.logout} isFair={true} />
              </WsContext.Provider>} />
            <PrivateRoute path="/presentation" isAuthenticated={this.state.isLoggedIn} render={(props) =>
              <WsContext.Provider value={this.webSock}>
                <PresentationHost {...props} onLogout={this.logout} />
              </WsContext.Provider>} />
            <PrivateRoute path="/main" isAuthenticated={this.state.isLoggedIn} render={(props) =>
              <WsContext.Provider value={this.webSock}>
                <Main {...props} isSubview onLogout={this.logout} />
              </WsContext.Provider>} />

            <Route path="/login" render={(props) =>
              <WsContext.Provider value={this.webSock}>
                <LoginHandler {...props} onLoginSuccess={this.login} onLogout={this.logout} isFair={true}/>
              </WsContext.Provider>} />
            <Route path="/view" render={(props) =>
              <WsContext.Provider value={this.webSock}>
                <PresentationClient {...props} isSubview />
              </WsContext.Provider>} />
            <Route path="/fair" render={(props) =>
              <WsContext.Provider value={this.webSock}>
                <LoginHandler {...props} onLoginSuccess={this.login} onLogout={this.logout} isFair={true}/>
              </WsContext.Provider>} />
            <Route path="/imprint" render={(props) =>
                <Impress {...props} />} />
            <Route render={(props) => <NotFound {...props} />} />
          </Switch>
        </BrowserRouter>
        :
        <div>
          <h1>Browser not supported! Please use one from the list</h1>
          <h2>Firefox v65+</h2>
          <h2>Chrome v70+</h2>
        </div>
      }

      <Portal />

    </div>
    );
  }
}

export default App;
