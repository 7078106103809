import React, {Component} from 'react';
import {Grid, Header, Button, Modal} from 'semantic-ui-react'

class SessionActive extends Component
{
  state = {
    isModalOpen: false
  }

  showModal = () => this.setState({isModalOpen: true})
  closeModal = () => this.setState({isModalOpen: false})

  render()
  {
    const {isModalOpen} = this.state

    return (<div style={{height: '100%'}}>
              <Modal size="mini" open={isModalOpen} onClose={this.closeModal}>
                <Modal.Header>Close previous session?</Modal.Header>
                <Modal.Content>
                  <p>Creating a new session will close your previous session</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button negative onClick={this.closeModal}>No</Button>
                  <Button positive onClick={this.props.onNewSession}>Yes</Button>
                </Modal.Actions>
              </Modal>
              <Grid textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450, marginTop: 15}}>
                  <Header as='h2' color='blue' textAlign='center'>An unclosed session is active.</Header>
                  <Header as='h4' color='grey' textAlign='center'>Please choose between new or previous session</Header>
                  <Button.Group size='big'>
                    <Button onClick={this.showModal}>New Session</Button>
                    <Button.Or />
                    <Button onClick={this.props.onRestoreSession}>Previous Session</Button>
                  </Button.Group>
                </Grid.Column>
              </Grid>
            </div>);
  }
}

export default SessionActive;
