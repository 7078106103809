import React, {Component} from 'react';

class NotFound extends Component
{
  render()
  {
    return (
      <h1>Not found</h1>
    );
  }
}

export default NotFound;
