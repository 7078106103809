import React, {Component} from 'react';
import StartupFeed from '../components/StartupFeed'
import SessionActive from '../components/SessionActive'
import MainTransition from '../pages/MainTransition'

class LoginTransition extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      isMainTransitionView: false,
      isPrevSessionActive: this.props.isPrevSessionActive || (localStorage.getItem("isPrevSessionActive") !== null)
    };
  }

  continueToMainView = () =>
  {
    this.setState({isPrevSessionActive: true});
    this.setState({isMainTransitionView: true});
  }

  render()
  {
    return (
      this.state.isMainTransitionView ? <MainTransition onLogout={this.props.onLogout}/> :
        this.state.isPrevSessionActive ? <SessionActive onNewSession={this.props.onNewSession}
                                                        onRestoreSession={this.continueToMainView}/> :
                                         <StartupFeed onLogout={this.props.onLogout} onFinished={this.continueToMainView}/>
    );
  }
}

export default LoginTransition;
