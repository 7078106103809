import React, {Component} from 'react';
import {Button, Header, Segment, Portal} from 'semantic-ui-react'

export default class MessagePortal extends Component
{
  constructor(props) {
    super(props);
    MessagePortal.instance = this;
  }

  state = { open: false, title: "", content: "" }

  handleClose = () => this.setState({ open: false })
  handleOpen = () => this.setState({ open: true })

  static show = (t, c) => {
    MessagePortal.instance.setState({title: t, content: c});
    MessagePortal.instance.handleOpen();
  }

  static hide() {
    MessagePortal.instance.handleClose();
  }

  render() {
    const { open } = this.state

    return (
        <Portal onClose={this.handleClose} open={open}>
          <Segment
            style={{
              position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
            }}
          >
            <Header>{this.state.title}</Header>
            <p>{this.state.content}</p>

            <Button
              content='Close Portal'
              negative
              onClick={this.handleClose}
            />
          </Segment>
        </Portal>
    )
  }
}