import React, { Component } from 'react';
import { Dropdown, Menu } from 'semantic-ui-react'
import Fullscreen from "react-full-screen";
import RFB from '@novnc/novnc/core/rfb';
import API from '../components/API'
import WsContext from "../components/WebSocketContext"

class PresentationHost extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.handleResetRegionClick = this.handleResetRegionClick.bind(this);
    this.createRegionResetJson = this.createRegionResetJson.bind(this);
    this.createSelectScreenJson = this.createSelectScreenJson.bind(this);
    this.connectedToServer = this.connectedToServer.bind(this);
    this.disconnectedFromServer = this.disconnectedFromServer.bind(this);
    this.updateDesktopName = this.updateDesktopName.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.rfbInit = this.rfbInit.bind(this);
  }

  api = new API();
  rfb = null;
  sessionId = "";

  rfbInit = (sess) => {
    if (!this.rfb) {
      this.rfb = new RFB(document.getElementById('canvasWrap'), this.api.getNovncWsAddr() + "/" + sess);
      this.rfb.scaleViewport = true;

      // Add listeners to important events from the RFB module
      this.rfb.addEventListener("connect", this.connectedToServer);
      this.rfb.addEventListener("disconnect", this.disconnectedFromServer);
      this.rfb.addEventListener("credentialsrequired", this.credentialsAreRequired);
      this.rfb.addEventListener("desktopname", this.updateDesktopName);
    }
  }

  componentDidMount() {
    const id = new URLSearchParams(window.location.search).get("id");
    
    this.props.webSock.connect(this.api.getWebsockAddr() + "/" + id);

    this.props.webSock.onmessage = evt => {
      const msg = JSON.parse(evt.data)

      if (msg["vnc_error"]) {
        this.rfb = null;
        setTimeout(function () {
          this.rfbInit();
        }.bind(this), 2000)
      }
      else if (msg["ping"]) {
        const msg = {
          ping: "pong"
        }
        this.props.webSock.send(JSON.stringify(msg));
      }
    }
    this.sessionId = id;
    this.rfbInit(id);
  }

  connectedToServer() {
  }

  disconnectedFromServer() {
  }

  updateDesktopName() {
  }

  handleFullscreen() {
    this.setState({ isFull: true });
  }

  createRegionResetJson(ID)
  {
    var ret = JSON.stringify({
      id: ID,
      resetReq: {
        isReset: true
      }
    });

    return ret;
  }

  createSelectScreenJson(ID, disp)
  {
    var ret = JSON.stringify({
      id: ID,
      selectScreen: {
        num: disp
      }
    });

    return ret;
  }

  handleResetRegionClick()
  {
    fetch(this.api.getRegion(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("sessionId")
      },
      body: this.createRegionResetJson(this.sessionId)
    }).catch(err => console.error("Error:", err));
  }

  handleScreenSelection(num) {
    fetch(this.api.getRegion(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("sessionId")
      },
      body: this.createSelectScreenJson(this.sessionId, num)
    }).catch(err => console.error("Error:", err));
  }

  render() {
    return (<div>
      <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({ isFull })}>
        <div className="ui">
          <div id="canvasWrap"></div>
        </div>

        <Menu compact style={{ position: "absolute", top: 10, right: 10, fontSize: 12 }}>
          <Dropdown simple item text='Presentation' direction="left">
            <Dropdown.Menu >
              <Dropdown.Header content='Screen selection' />
              <Dropdown.Item text='Navigation' onClick={() => this.handleScreenSelection(0)}/>
              <Dropdown.Item text='Overhead Panel' onClick={() => this.handleScreenSelection(1)} />
              <Dropdown.Item text='Instrument Console' onClick={() => this.handleScreenSelection(2)} />
              <Dropdown.Item text='Split View' onClick={() => this.handleScreenSelection(4)} />
              <Dropdown.Divider />
              <Dropdown.Item text='Reset screen' onClick={this.handleResetRegionClick} />
              <Dropdown.Item text='Go fullscreen' onClick={this.handleFullscreen} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </Fullscreen>
    </div>);
  }
}

const PresentationHostWithSocket = props => (
  <WsContext.Consumer>
    {webSock => <PresentationHost {...props} webSock={webSock} />}
  </WsContext.Consumer>
)

export default PresentationHostWithSocket;
