import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component
{
  render()
  {
    return (
      <Route render={(a) =>
        this.props.isAuthenticated ?
          this.props.render()
        :
        <Redirect
          to={{
            pathname: "/login",
            state: { from: a.location }
          }}
        />
        } />
    );
  }
}

export default PrivateRoute;
