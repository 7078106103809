class WebSocketClient
{
    ws = null;
    url = null;
    isReconnect = true;

    connect = (url) =>
    {
        this.url = url;
        this.ws = new WebSocket(url);
        this.ws.onopen = this.wraponopen;
        this.ws.onmessage = this.wraponmessage;
        this.ws.onclose = this.wraponclose;
        this.ws.onerror = this.wraponerror;
    }

    reconnect = () =>
    {
        if(!this.isReconnect)
            return;

        setTimeout(function(){
            this.connect(this.url);
        }.bind(this), 5000);
    }

    sockState = () =>
    {
        return this.ws.readyState;
    }

    wraponopen = (...args) =>
    {
        this.onopen(...args);
    }

    wraponmessage = (...args) =>
    {
        this.onmessage(...args);
    }

    wraponclose = (...args) =>
    {
        this.reconnect();

        this.onclose(...args);
    }

    wraponerror = (...args) =>
    {
        this.reconnect();

        this.onerror(...args);
    }

    send = (...args) =>
    {
        this.ws.send(...args);
    }

    shutdown = () =>
    {
        this.isReconnect = false;
        this.ws.close();
        this.ws = null;
    }

    onopen = (...args) => { console.log("onopen", ...args) }
    onmessage = (...args) => { console.log("onmessage", ...args) }
    onclose = (...args) => { console.log("onclose", ...args) }
    onerror = (...args) => { console.log("onerror", ...args) }
}

export default WebSocketClient;