import React, {Component} from 'react';
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react'
import API from './API'
import sha3 from 'js-sha3';

class Register extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      email: undefined,
      pass: undefined,
      emailError: false,
      passError: false,
      formError: false,
      createUserError: false,
      registerSuccess: false
    };
  }

  reset()
  {
    this.setState({
      emailError: false,
      passError: false,
      formError: false,
      createUserError: false,
      registerSuccess: false
    });
  }

  api = new API();

  putUser(email, pass, endpoint)
  {
    fetch(endpoint, {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        username: email,
        password: pass
      })
    }).then(res => res.json())
    .then(res => {
      if(res["status"] === 1 || res.hasOwnProperty("error"))
        this.setState({createUserError: true});
      else
        this.setState({registerSuccess: true});
    })
    .catch(err => console.error("Error:", err));
  }

  onSubmit = (e) =>
  {
    e.preventDefault();
    this.reset();

    var error = false;

    if (this.state.email === '' || !this.state.email.includes('@') || !this.state.email.includes('.'))
    {
      this.setState({emailError: true});
      error = true;
    }
    else
      this.setState({emailError: false});

    if (this.state.pass.length < 6)
    {
      this.setState({passError: true});
      error = true;
    }
    else
      this.setState({passError: false});

    if (error)
    {
      this.setState({formError: true});
      return;
    }

    this.setState({formError: false});

    this.putUser(this.state.email, sha3.keccak512(this.state.pass), this.api.getUser());
  }

  render()
  {
    return (
      <div id='loginBox'>
        <Grid textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
          <Grid.Column style={{maxWidth: 450, marginTop: 15}}>
          {this.state.createUserError ?
              <Message error header='Username already used' content="Please choose a different username" />
              :
              null
            }
            {this.state.emailError ?
              <Message error header='Wrong email' content="Email address is invalid" />
              :
              null
            }
            {this.state.passError ?
              <Message error header='Invalid password' content="Password needs at least 8 characters" />
              :
              null
            }
            {this.state.registerSuccess ?
              <Message positive header='Account created' content="You can now login to your account" />
              :
              null
            }
            <Header as='h2' color='blue' textAlign='center'>Create a new ACT account</Header>
            <Form size='large'onSubmit={(event) => {this.onSubmit(event);}}
              error={this.state.createUserError}>
              <Segment stacked>
                <Form.Input fluid icon='user'
                  iconPosition='left'
                  placeholder='E-mail address'
                  onChange={(e) => this.setState({email: e.target.value})}
                  error={this.state.emailError || this.state.createUserError} />
                <Form.Input fluid icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  onChange={(e) => this.setState({pass: e.target.value})}
                  error={this.state.passError} />

                <Button color='blue' fluid size='large' disabled={!this.state.email || !this.state.pass}>
                  Create
                </Button>
              </Segment>
            </Form>
            <Message>
              <Button size='mini' onClick={this.props.onLogin}>Login</Button>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Register;
