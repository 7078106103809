import React, {Component} from 'react';
import {Button, Step, Container, Header, Message} from 'semantic-ui-react'
import StartupFeedEvent from './StartupFeedEvent'
import WsContext from "../components/WebSocketContext"
import API from './API'

class StartupFeed extends Component
{
  state = {
    sfe1State: 0,
    sfe2State: 0,
    sfe3State: 0,
    error: false,
    errorVisible: true,
    errorMsg: ""
  }
  
  api = new API();
  currentdate = new Date();

  handleDismiss = () => {
    this.setState({ errorVisible: false })
  }

  componentDidMount()
  {
    if(this.props.webSock.sockState !== 1)
      this.props.webSock.connect(this.api.getWebsockAddr() + "/" + localStorage.getItem("sessionId"));

    this.props.webSock.onmessage = evt =>
    {
      const msg = JSON.parse(evt.data)

      if(msg["error"])
      {
        this.setState({
          error: true,
          errorMsg: msg["error"],
          errorVisible: true
        });
      }
      else if(msg["ping"])
      {
        const msg = {
          ping: "pong"
        }
        this.props.webSock.send(JSON.stringify(msg));
      }
      else if (msg["status"] === 1)
      {
        this.setState({
          sfe1State: 1,
          sfe2State: 0,
          sfe3State: 0
        });

        setTimeout(function()
        {
          this.props.onFinished();
        }.bind(this), 250)
      }
      else if (msg["status"] === 2)
      {
        this.setState({
          sfe1State: 2,
          sfe2State: 1,
          sfe3State: 0
        });
      }
      else if (msg["status"] === 3)
      {
        this.setState({
          sfe1State: 2,
          sfe2State: 2,
          sfe3State: 1
        });

        setTimeout(function()
        {
          this.props.onFinished();
        }.bind(this), 500)
      }
      else if(msg["logout"])
      {
        if(msg["logout"] === localStorage.getItem("sessionId"))
        {
          this.props.onLogout();
        }
      }
    }
  }

  logout()
  {
    localStorage.removeItem("sessionId");
    localStorage.removeItem("isPrevSessionActive");
    localStorage.removeItem("roomType");

    window.location.replace("/");
  }

  render()
  {
    return (
      <Container id="startupFeedWrapper">
        {this.state.error && this.state.errorVisible ?
          <Message error header='Error' content={this.state.errorMsg} onDismiss={this.handleDismiss} />
          :
          null
        }

        <Button style={{ position: "absolute", top: 10, right: 10, fontSize: 12 }} onClick={this.logout}>Logout</Button>
        
        <Header as='h2' color='blue' textAlign='center'>Starting your ACT...</Header>
        <Container style={{width: ""}}>
          <Step.Group ordered fluid>
            <StartupFeedEvent summary="Preparing ACT machine" currentState={this.state.sfe1State} />
            <StartupFeedEvent summary="Booting ACT machine" currentState={this.state.sfe2State} />
            <StartupFeedEvent summary="ACT ready. Connecting." currentState={this.state.sfe3State} />
          </Step.Group>
        </Container>
      </Container>
    );
  }
}

const StartupFeedWithSocket = props => (
  <WsContext.Consumer>
    {webSock => <StartupFeed {...props} webSock={webSock} />}
  </WsContext.Consumer>
)

export default StartupFeedWithSocket;