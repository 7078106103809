import React, { Component } from 'react';
import { Modal, Button, Label, Segment, Dropdown, Menu, Table } from 'semantic-ui-react'
import Fullscreen from "react-full-screen";
import RFB from '@novnc/novnc/core/rfb';
import API from '../components/API'
import WsContext from "../components/WebSocketContext"
import Subview from './Subview'

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      log: [],
      logTime: [],
      subViews: [],
      subViewsId: 0,
      logCount: 0,
      showLogoutPrompt: false,
      remainingTime: 0,
      selectedView: "all"
    };

    this.handleResetRegionClick = this.handleResetRegionClick.bind(this);
    this.createRegionResetJson = this.createRegionResetJson.bind(this);
    this.createSelectScreenJson = this.createSelectScreenJson.bind(this);
    this.connectedToServer = this.connectedToServer.bind(this);
    this.disconnectedFromServer = this.disconnectedFromServer.bind(this);
    this.updateDesktopName = this.updateDesktopName.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.handleSubview = this.handleSubview.bind(this);
    this.scale = this.scale.bind(this);
  }

  clearLog = () =>
    this.setState({ log: [], logTime: [], logCount: 0 })

  writeLog = eventName =>
    this.setState(prevState => ({
      log: [`${eventName}`, ...prevState.log].slice(0, 20),
      logTime: [`${new Date().toLocaleTimeString('de-DE')}`, ...prevState.logTime].slice(0, 20),
      logCount: prevState.logCount + 1,
    }))

  api = new API();
  rfb = null;
  windowResizeTimeout = null;

  windowResized = () => {
    this.scale(this.state.selectedView);
  }

  rfbInit = () => {
    if (!this.rfb) {
      this.rfb = new RFB(document.getElementById('canvasWrap'), this.api.getNovncWsAddr() + "/" + localStorage.getItem("sessionId"));
      this.rfb.scaleViewport = true;

      // Add listeners to important events from the RFB module
      this.rfb.addEventListener("connect", this.connectedToServer);
      this.rfb.addEventListener("disconnect", this.disconnectedFromServer);
      this.rfb.addEventListener("credentialsrequired", this.credentialsAreRequired);
      this.rfb.addEventListener("desktopname", this.updateDesktopName);
    }
  }

  closeWindowPortals = () => {
    this.setState({subViews: [], subViewsId: 0});
  }

  inputTimout = null;
  logoutTimeout = null;
  refreshTimer = null;

  refreshRemainingTime = () =>{
    var timeout = this.timeoutStamp==null? new Date() : this.timeoutStamp;
    this.setState({
      remainingTime: Math.trunc(((timeout.getTime()+60000) - (new Date()).getTime())/1000)
    });

    this.refreshTimer = window.setTimeout(this.refreshRemainingTime, 1000); // 1 s
  }

  handleTimeoutEvent = () => {
    if(this.logoutTimeout != null)
      return;

    if(this.inputTimout != null)
      window.clearTimeout(this.inputTimout);

    this.inputTimout = window.setTimeout(this.handleTimeout, 10*60*1000); // 10 min
  }

  timeoutStamp = null;
  handleTimeout = () => {
    this.timeoutStamp = new Date();
    
    this.toggleTimeoutWindowVisibility(true);
    this.logoutTimeout = window.setTimeout(this.props.onLogout, 60*1000); // 1 min

    this.refreshRemainingTime();
  }

  toggleTimeoutWindowVisibility = (state) => {
    this.setState({
      showLogoutPrompt: state
    });
  }

  resetTimers = () => {
    if(this.inputTimout != null)
      window.clearTimeout(this.inputTimout);
    this.inputTimout = null;

    if(this.logoutTimeout != null)
      window.clearTimeout(this.logoutTimeout);
    this.logoutTimeout = null;

    if(this.refreshTimer != null)
      window.clearTimeout(this.refreshTimer);
    this.refreshTimer = null;

    this.toggleTimeoutWindowVisibility(false);
    this.handleTimeoutEvent();
  }
  
  getRemainingTime = () => {
    return this.state.remainingTime;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', () => {
      this.closeWindowPortals();
    });

    window.addEventListener('resize', () => {
      clearTimeout(this.windowResizeTimeout);
      this.windowResizeTimeout = setTimeout(this.windowResized, 100);
    });

    this.props.webSock.onmessage = evt => {
      const msg = JSON.parse(evt.data)

      if (msg["vnc_error"]) {
        this.rfb = null;
        setTimeout(function () {
          this.rfbInit();
        }.bind(this), 2000)
        this.writeLog(msg["vnc_error"]);
      }
      else if (msg["ping"]) {
        const msg = {
          ping: "pong"
        }
        this.props.webSock.send(JSON.stringify(msg));
      }
      else if (msg["logout"]) {
        if (msg["logout"] === localStorage.getItem("sessionId")) {
          if (this.props.onLogout)
            this.props.onLogout();
        }
      }
    }

    this.rfbInit();

    document.body.addEventListener("mousemove", this.handleTimeoutEvent);
    document.body.addEventListener("keydown",   this.handleTimeoutEvent);
    this.handleTimeoutEvent();
  }

  connectedToServer() {
  }

  disconnectedFromServer() {
  }

  updateDesktopName() {
  }

  createRegionResetJson(ID) {
    var ret = JSON.stringify({
      id: ID,
      resetReq: {
        isReset: true
      }
    });

    return ret;
  }

  createSelectScreenJson(ID, disp) {
    var ret = JSON.stringify({
      id: ID,
      selectScreen: {
        num: disp
      }
    });

    return ret;
  }

  scale(viewport) {

    this.setState({selectedView: viewport});

    if (this.rfb) {
      this.rfb.scaleViewport = true;
      this.rfb._updateScale();
      this.rfb._display.scale = 1.0;
      this.rfb.scaleViewport = false;
      this.rfb.clipViewport = true;
      this.rfb._display.viewportChangePos(0, 0);

      switch (viewport) {
        case "all":
          this.rfb._display.viewportChangePos(0, 0);
          this.rfb.scaleViewport = true;
          this.rfb._updateScale();
          break;
        case "ohp":
          this.rfb._display.viewportChangeSize(1920, 1080);
          this.rfb._display.autoscale(window.innerWidth, window.innerHeight);
          this.rfb._fixScrollbars();
          this.rfb._display.viewportChangePos(1920, 0);
          break;
        case "nav":
          this.rfb._display.viewportChangeSize(1920, 1080);
          this.rfb._display.autoscale(window.innerWidth, window.innerHeight);
          this.rfb._fixScrollbars(); 
          this.rfb._display.viewportChangePos(0, 1080);
          break;
        case "inst":
          this.rfb._display.viewportChangeSize(1920, 1080);
          this.rfb._display.autoscale(window.innerWidth, window.innerHeight);
          this.rfb._fixScrollbars();
          this.rfb._display.viewportChangePos(1920, 1080);
          break;
        case "ios":

          this.rfb._display.viewportChangeSize(1920, 1080);
          this.rfb._display.autoscale(window.innerWidth, window.innerHeight);
          this.rfb._fixScrollbars();
          this.rfb._display.viewportChangePos(0, 0);
          break;
        default:
          break;
      }
    }
  }

  handleResetRegionClick() {
    fetch(this.api.getRegion(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("sessionId")
      },
      body: this.createRegionResetJson(localStorage.getItem("sessionId"))
    }).catch(err => console.error("Error:", err));
  }

  handleScreenSelection(num) {
    fetch(this.api.getRegion(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("sessionId")
      },
      body: this.createSelectScreenJson(localStorage.getItem("sessionId"), num)
    }).catch(err => console.error("Error:", err));
  }

  handleFullscreen() {
    this.setState({ isFull: true });
  }

  handleSubview() {
    const elem = <Subview closeWindowPortal={this.handleSubviewClose} url="/main"/>;

    this.setState({subViewsId: this.state.subViewsId+1});

    this.setState(state => {
      const subViews = [...state.subViews, elem];

      return {
        subViews
      };
    });
  }

  handleSubviewClose = i =>
  {
    this.setState(state => {
      const subViews = state.subViews.filter((item, j) => i !== j);

      return {
        subViews
      };
    });
  }

  render() {
    const { log, logTime, logCount } = this.state

    var rows = [];
    for (var i = 0; i < log.length; i++)
      rows.push(<Table.Row key={i} ><Table.Cell>{logTime[i]}</Table.Cell><Table.Cell>{log[i]}</Table.Cell></Table.Row>);

    return (<div>
      <div id="subviewsContainer">
        {this.state.subViews.map((item, index) => (
          <div key={index}>
            {item}
          </div>
        ))}
      </div>
      <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({ isFull })}>
        <div className="ui">
          <div id="canvasWrap"></div>
        </div>

        
        <Modal
          size='large'
          open={this.state.showLogoutPrompt}
          onClose={() => this.resetTimers()}
        >
          <Modal.Header>Logout Warning</Modal.Header>
          <Modal.Content>
            <p>Your session is closing, please press confirm. { this.getRemainingTime()  } seconds left.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={() => this.resetTimers()}>
              Confirm
            </Button>
          </Modal.Actions>
        </Modal>



        <Menu compact style={{ position: "absolute", top: 10, right: 10, fontSize: 12 }}>
          <Dropdown simple item text='ACT' direction="left">
            <Dropdown.Menu>
              <Dropdown.Header content='Screen selection' />
              <Dropdown.Item text='Navigation' onClick={() => this.scale("nav")} />
              <Dropdown.Item text='Overhead Panel' onClick={() => this.scale("ohp")} />
              <Dropdown.Item text='Instrument Console' onClick={() => this.scale("inst")} />
              <Dropdown.Item text='IOS' onClick={() => this.scale("ios")} />
              {/* <Dropdown.Item text='Split View' onClick={() => this.handleScreenSelection(4)} /> */}
              {this.props.isSubview ? 
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item text='Reset screen' onClick={() => this.scale("all")} />
                  <Dropdown.Item text='Go fullscreen' onClick={this.handleFullscreen} />
                </> :
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item text='Reset screen' onClick={() => this.scale("all")} />
                  <Dropdown.Item text='Go fullscreen' onClick={this.handleFullscreen} />
                  <Dropdown.Item text='Open subview' onClick={this.handleSubview} />
                  <Dropdown.Divider />
                  <Dropdown.Item text='Logout' onClick={this.props.onLogout} />
                </>}
            </Dropdown.Menu>
          </Dropdown>
          {log.length ?
            <Dropdown simple item direction="left" icon={null} trigger={<span><Label circular>{logCount}</Label></span>}>
              <Dropdown.Menu>
                <Segment.Group >
                  <Segment >
                    <Button compact size='small' floated='right' onClick={this.clearLog}>
                      Clear
                  </Button>
                  Event Log <Label circular>{logCount}</Label>
                  </Segment>
                  <Segment secondary style={{ minWidth: "200px" }}>
                    <Table color="blue" >
                      <Table.Body >{rows}</Table.Body>
                    </Table>
                  </Segment>
                </Segment.Group>
              </Dropdown.Menu>
            </Dropdown>
            :
            null}
        </Menu>
      </Fullscreen>
    </div>);
  }
}

const MainWithSocket = props => (
  <WsContext.Consumer>
    {webSock => <Main {...props} webSock={webSock} />}
  </WsContext.Consumer>
)

export default MainWithSocket;

