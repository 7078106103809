import React, { Component } from 'react';
import { Button, Label, Segment, Dropdown, Menu, Table, Grid, Modal } from 'semantic-ui-react'
import Fullscreen from "react-full-screen";
import RFB from '@novnc/novnc/core/rfb';
import API from '../components/API'
import WsContext from "../components/WebSocketContext"
import Subview from './Subview'

class Classroom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      log: [],
      logTime: [],
      subViews: [],
      subViewsId: 0,
      userCount: 0,
      logCount: 0,
      presentation: <></>,
      isPresentationViewOpen: false,
      isShareSessionOpen: false,
      shareSession: "",
      shareLink: "Not connected"
    };

    this.handleResetRegionClick = this.handleResetRegionClick.bind(this);
    this.createRegionResetJson = this.createRegionResetJson.bind(this);
    this.createSelectScreenJson = this.createSelectScreenJson.bind(this);
    this.connectedToServer = this.connectedToServer.bind(this);
    this.disconnectedFromServer = this.disconnectedFromServer.bind(this);
    this.updateDesktopName = this.updateDesktopName.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.handleSubview = this.handleSubview.bind(this);
    this.handlePresentation = this.handlePresentation.bind(this);
    this.handlePresentationClose = this.handlePresentationClose.bind(this);
    this.handleShareSession = this.handleShareSession.bind(this);
    this.handleSessionShareClose = this.handleSessionShareClose.bind(this);
    this.scale = this.scale.bind(this);
  }

  clearLog = () =>
    this.setState({ log: [], logTime: [], logCount: 0 })

  writeLog = eventName =>
    this.setState(prevState => ({
      log: [`${eventName}`, ...prevState.log].slice(0, 20),
      logTime: [`${new Date().toLocaleTimeString('de-DE')}`, ...prevState.logTime].slice(0, 20),
      logCount: prevState.logCount + 1,
    }))

  addUser = name =>
    this.setState(prevState => ({
      users: [`${name}`, ...prevState.users],
      userCount: prevState.userCount + 1,
    }))

  api = new API();
  rfb = null;

  rfbInit = () => {
    if (!this.rfb) {
      this.rfb = new RFB(document.getElementById('canvasWrap'), this.api.getNovncWsAddr() + "/" + localStorage.getItem("sessionId"));
      this.rfb.scaleViewport = true;

      // Add listeners to important events from the RFB module
      this.rfb.addEventListener("connect", this.connectedToServer);
      this.rfb.addEventListener("disconnect", this.disconnectedFromServer);
      this.rfb.addEventListener("credentialsrequired", this.credentialsAreRequired);
      this.rfb.addEventListener("desktopname", this.updateDesktopName);
    }
  }

  closeWindowPortals = () => {
    this.setState({ subViews: [], subViewsId: 0 });
  }

  componentDidMount() {
    window.addEventListener('beforeunload', () => {
      this.closeWindowPortals();
    });

    this.addUser("Me");
    this.addUser("User Name");

    this.props.webSock.onmessage = evt => {
      const msg = JSON.parse(evt.data)

      if (msg["vnc_error"]) {
        this.rfb = null;
        setTimeout(function () {
          this.rfbInit();
        }.bind(this), 2000)
        this.writeLog(msg["vnc_error"]);
      }
      else if (msg["ping"]) {
        const msg = {
          ping: "pong"
        }
        this.props.webSock.send(JSON.stringify(msg));
      }
      else if (msg["logout"]) {
        if (msg["logout"] === localStorage.getItem("sessionId")) {
          if (this.props.onLogout)
            this.props.onLogout();
        }
      }
      else if (msg["sharedId"]) {
        this.setState({ shareSession: msg["sharedId"] });
        this.setState({ shareLink: window.location.origin + "/view?id=" + msg["sharedId"] });
      }
    }
    
    if(this.props.webSock.sockState() === 1)
      this.props.webSock.send(JSON.stringify({makeshared: 1}));

    this.rfbInit();
  }

  connectedToServer() {
  }

  disconnectedFromServer() {
  }

  updateDesktopName() {
  }

  createRegionResetJson(ID) {
    var ret = JSON.stringify({
      id: ID,
      resetReq: {
        isReset: true
      }
    });

    return ret;
  }

  createSelectScreenJson(ID, disp) {
    var ret = JSON.stringify({
      id: ID,
      selectScreen: {
        num: disp
      }
    });

    return ret;
  }

  scale(viewport) {
    if (this.rfb) {
      this.rfb._display.scale = 1.0;
      this.rfb.scaleViewport = false;
      this.rfb.clipViewport = true;
      this.rfb._display.viewportChangePos(-5760, -3000);

      switch (viewport) {
        case "all":
          this.rfb._display.viewportChangePos(0, 0);
          this.rfb.scaleViewport = true;
          this.rfb._updateScale();
          break;
        case "ohp":
          this.rfb._display.viewportChangePos((5760 - 1920) / 2, 0);
          break;
        case "nav":
          this.rfb._display.viewportChangePos(0, 1920);
          break;
        case "inst":
          this.rfb._display.viewportChangePos(1920, 1920);
          break;
        case "ios":
          this.rfb._display.viewportChangePos(3840, 1920);
          break;
        default:
          break;
      }
    }
  }

  handleResetRegionClick() {
    fetch(this.api.getRegion(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("sessionId")
      },
      body: this.createRegionResetJson(localStorage.getItem("sessionId"))
    }).catch(err => console.error("Error:", err));
  }

  handleScreenSelection(num) {
    fetch(this.api.getRegion(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("sessionId")
      },
      body: this.createSelectScreenJson(localStorage.getItem("sessionId"), num)
    }).catch(err => console.error("Error:", err));
  }

  handleFullscreen() {
    this.setState({ isFull: true });
  }

  handleSubview() {
    const elem = <Subview closeWindowPortal={this.handleSubviewClose} url="/main" />;

    this.setState({ subViewsId: this.state.subViewsId + 1 });

    this.setState(state => {
      const subViews = [...state.subViews, elem];

      return {
        subViews
      };
    });
  }

  handlePresentation() {
    this.setState({ presentation: <Subview onClose={this.handlePresentationClose} url={"/presentation?id=" + this.state.shareSession} /> });
    this.setState({ isPresentationViewOpen: true });
  }

  handlePresentationClose() {
    this.setState({ isPresentationViewOpen: false });
    this.setState({ presentation: <></> });
  }

  handleShareSession() {
    this.setState({ isShareSessionOpen: true });
  }

  handleSessionShareClose() {
    this.setState({ isShareSessionOpen: false });
  }

  handleSubviewClose = i => {
    this.setState(state => {
      const subViews = state.subViews.filter((item, j) => i !== j);

      return {
        subViews
      };
    });
  }

  render() {
    const { users, userCount } = this.state
    const { log, logTime, logCount } = this.state

    var userRows = [];
    for (let i = 0; i < users.length; i++)
      userRows.push(<Table.Row key={i}><Table.Cell>{users[i]}</Table.Cell><Table.Cell>{i !== users.length-1 ? <Button size='mini'>Make presenter</Button> : <></>}</Table.Cell></Table.Row>);

    var logRows = [];
    for (let i = 0; i < log.length; i++)
      logRows.push(<Table.Row key={i} ><Table.Cell>{logTime[i]}</Table.Cell><Table.Cell>{log[i]}</Table.Cell></Table.Row>);

    return (<div>
      <div id="subviewsContainer">
        {this.state.subViews.map((item, index) => (
          <div key={index}>
            {item}
          </div>
        ))}
      </div>
      <div id="presentationContainer">
        {this.state.isPresentationViewOpen ? this.state.presentation : <></>}
      </div>
      <div>
        <Modal open={this.state.isShareSessionOpen} onClose={this.handleSessionShareClose} closeIcon>
          <Modal.Header>Share session</Modal.Header>
          <Modal.Content>
            <p>Copy the URL below and send to relevant participants:</p>
            <p>{this.state.shareLink}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={this.handleSessionShareClose}>
              Close
          </Button>
          </Modal.Actions>
        </Modal>
      </div>
      <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({ isFull })}>
        <div className="ui">
          <div id="canvasWrap"></div>
        </div>

        <Menu compact style={{ position: "absolute", top: 10, right: 10, fontSize: 12 }}>
          <Dropdown simple item text='ACT' direction="left">
            <Dropdown.Menu >
              <Dropdown.Header content='Presenting screen' />
              <Dropdown.Item text='Open presentation view' onClick={this.handlePresentation} />
              <Dropdown.Divider />
              <Grid>
                <Grid.Column textAlign="center">
                  <Button color="blue" size="mini" onClick={this.handleShareSession}>Share session</Button>
                </Grid.Column>
              </Grid>
              <Dropdown.Divider />
              <Dropdown.Header content='Your screen' />
              <Dropdown.Item text='Navigation' onClick={() => this.scale("nav")} />
              <Dropdown.Item text='Overhead Panel' onClick={() => this.scale("ohp")} />
              <Dropdown.Item text='Instrument Console' onClick={() => this.scale("inst")} />
              <Dropdown.Item text='IOS' onClick={() => this.scale("ios")} />
              {this.props.isSubview ?
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item text='Reset screen' onClick={() => this.scale("all")} />
                  <Dropdown.Item text='Go fullscreen' onClick={this.handleFullscreen} />
                </> :
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item text='Reset screen' onClick={() => this.scale("all")} />
                  <Dropdown.Item text='Go fullscreen' onClick={this.handleFullscreen} />
                  <Dropdown.Item text='Open subview' onClick={this.handleSubview} />
                  <Dropdown.Divider />
                  <Dropdown.Item text='Logout' onClick={this.props.onLogout} />
                </>}
            </Dropdown.Menu>
          </Dropdown>
          {users.length ?
            <Dropdown simple item direction="left" icon={null} trigger={<span><Label circular>{userCount}</Label></span>}>
              <Dropdown.Menu>
                <Segment.Group >
                  <Segment>
                    Connected users <Label circular>{userCount}</Label>
                  </Segment>
                  <Segment secondary style={{ minWidth: "200px" }}>
                    <Table color="blue" >
                      <Table.Body >{userRows}</Table.Body>
                    </Table>
                  </Segment>
                </Segment.Group>
              </Dropdown.Menu>
            </Dropdown>
            :
            null}
          {log.length ?
            <Dropdown simple item direction="left" icon={null} trigger={<span><Label circular>{logCount}</Label></span>}>
              <Dropdown.Menu>
                <Segment.Group >
                  <Segment >
                    <Button compact size='small' floated='right' onClick={this.clearLog}>
                      Clear
                  </Button>
                  Event Log <Label circular>{logCount}</Label>
                  </Segment>
                  <Segment secondary style={{ minWidth: "200px" }}>
                    <Table color="blue" >
                      <Table.Body >{logRows}</Table.Body>
                    </Table>
                  </Segment>
                </Segment.Group>
              </Dropdown.Menu>
            </Dropdown>
            :
            null}
        </Menu>
      </Fullscreen>
    </div>);
  }
}

const ClassroomWithSocket = props => (
  <WsContext.Consumer>
    {webSock => <Classroom {...props} webSock={webSock} />}
  </WsContext.Consumer>
)

export default ClassroomWithSocket;
