import React, {Component} from 'react';
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react'
import API from './API'
import sha3 from 'js-sha3';

class Login extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      email: undefined,
      pass: undefined,
      emailError: false,
      formError: false,
      loginError: false
    };

    this.putUser = this.putUser.bind(this);
  }

  api = new API();

  createNewSession()
  {
    console.log("createNewSession");
  }

  putUser(email, pass, endpoint)
  {
    fetch(endpoint, {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        username: email,
        password: pass
      })
    }).then(res => res.json())
      .then(res =>
      {
        if (res["token"] !== undefined)
        {
          var id = res["token"];
          localStorage.setItem("sessionId", id);
          if (res["isNewSession"])
          {
            localStorage.removeItem("isPrevSessionActive");
          }
          else
          {
            localStorage.setItem("isPrevSessionActive", "true");
            this.props.onSessionActive();
          }

          this.props.onLoginSuccess();
        }
        else if(res["error"] !== undefined)
        {
          this.setState({loginError: true});
        }
      })
      .catch(err => console.error("Error:", err));
  }

  onSubmit = (e) =>
  {
    e.preventDefault();

    var error = false;

    if (this.state.email === '' || !this.state.email.includes('@') || !this.state.email.includes('.'))
    {
      this.setState({emailError: true});
      error = true;
    }
    else
      this.setState({emailError: false});

    if (error)
    {
      this.setState({formError: true});
      return;
    }

    this.setState({formError: false});

    this.putUser(this.state.email, sha3.keccak512(this.state.pass), this.api.getSession());
  }

  render()
  {
    return (
      <div id='loginBox'>
        <Grid textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
          <Grid.Column style={{maxWidth: 450, marginTop: 15}}>
            {this.state.emailError ?
              <Message error header='Wrong email' content="Email address is invalid" />
              :
              null
            }
            {this.state.loginError ?
              <Message error header='Wrong credentials' content="Email and/or password is wrong" />
              :
              null
            }
            <Header as='h2' color='blue' textAlign='center'>Login to your ACT account</Header>
            <Form size='large' onSubmit={(event) => {this.onSubmit(event);}}
              error={this.state.formError}>
              <Segment stacked>
                <Form.Input fluid icon='user'
                  iconPosition='left'
                  placeholder='E-mail address'
                  onChange={(e) => this.setState({email: e.target.value})}
                  error={this.state.emailError} />
                <Form.Input fluid icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  onChange={(e) => this.setState({pass: e.target.value})}
                  error={this.state.passError} />
                <Button color='blue' fluid size='large' disabled={!this.state.email || !this.state.pass}>
                  Login
                </Button>
              </Segment>
            </Form>
            {/* <Message>
              <Button size='mini' onClick={this.props.onRegister}>Sign Up</Button>
            </Message> */}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Login;
