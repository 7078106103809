import React, { Component } from 'react';
import API from '../components/API'
import Register from '../components/Register'
import LoginTransiton from '../pages/LoginTransition'
import Login from '../components/Login'
import EvaluationLogin from '../pages/EvaluationLogin'

class LoginHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRegister: false,
      isPrevSessionActive: (localStorage.getItem("isPrevSessionActive") !== null),
      isLoggedIn: (localStorage.getItem("sessionId") !== null),
      isFair: props.isFair
    };
    this.child = React.createRef();
    this.onRegister = this.onRegister.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onSessionActive = this.onSessionActive.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.logout = this.logout.bind(this);
    this.onNewSession = this.onNewSession.bind(this);
  }

  api = new API();

  onRegister() {
    this.setState({ isRegister: true });
  }

  onLogin() {
    this.setState({ isRegister: false });
  }

  onSessionActive() {
    this.setState({ isPrevSessionActive: true });
  }

  onLoginSuccess() {
    this.setState({ isLoggedIn: true });
    this.props.onLoginSuccess();
  }

  logout() {
    fetch(this.api.getSession(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": localStorage.getItem("sessionId")
      },
      body: JSON.stringify({
        id: localStorage.getItem("sessionId")
      })
    }).catch(err => console.error("Error:", err));

    localStorage.removeItem("sessionId");
    localStorage.removeItem("isPrevSessionActive");
    localStorage.removeItem("roomType");
  }

  onLogout() {
    this.logout();

    this.setState({ isLoggedIn: false });
    this.setState({ isPrevSessionActive: false });
    this.props.onLogout();
  }

  onNewSession() {
    this.logout();

    this.child.current.createNewSession();
  }

  render() {
    const isLoggedIn = this.state.isLoggedIn;
    const isRegister = this.state.isRegister;
    const isFair = this.state.isFair;
    let wrapper;

    if (isLoggedIn) {
      wrapper = <LoginTransiton onLogout={this.onLogout}
        onNewSession={this.onNewSession}
        isPrevSessionActive={this.state.isPrevSessionActive} />
    }
    else {
      if (isRegister)
        wrapper = <Register onLogin={this.onLogin} />;
      else if (isFair)
        wrapper = <EvaluationLogin onLogin={this.onLogin}
          onSessionActive={this.onSessionActive}
          onLoginSuccess={this.onLoginSuccess} location={this.props.location}/>;
      else
        wrapper = <Login onRegister={this.onRegister}
          onSessionActive={this.onSessionActive}
          onLoginSuccess={this.onLoginSuccess}
          ref={this.child} />
    }

    return (<div style={{ height: '100%' }}>{wrapper}</div>);
  }
}

export default LoginHandler;
