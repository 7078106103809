import React, { Component } from 'react';
import { Dropdown, Menu, Button, Form, Grid, Header, Segment} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import Fullscreen from "react-full-screen";
import RFB from '@novnc/novnc/core/rfb';
import API from '../components/API'
import WsContext from "../components/WebSocketContext"

class PresentationClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      isLoggedIn: false
    };

    this.connectedToServer = this.connectedToServer.bind(this);
    this.disconnectedFromServer = this.disconnectedFromServer.bind(this);
    this.updateDesktopName = this.updateDesktopName.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.rfbInit = this.rfbInit.bind(this);
  }

  api = new API();
  rfb = null;

  rfbInit = (sess) => {
    if (!this.rfb) {
      this.rfb = new RFB(document.getElementById('canvasWrap'), this.api.getNovncWsAddr() + "/" + sess);
      this.rfb.scaleViewport = true;
      this.rfb.viewOnly = true;

      // Add listeners to important events from the RFB module
      this.rfb.addEventListener("connect", this.connectedToServer);
      this.rfb.addEventListener("disconnect", this.disconnectedFromServer);
      this.rfb.addEventListener("credentialsrequired", this.credentialsAreRequired);
      this.rfb.addEventListener("desktopname", this.updateDesktopName);
    }
  }

  componentDidMount() {
    const id = new URLSearchParams(this.props.location.search).get("id");
    this.props.webSock.connect(this.api.getWebsockAddr() + "/" + id);

    this.props.webSock.onmessage = evt => {
      const msg = JSON.parse(evt.data)

      if (msg["vnc_error"]) {
        this.rfb = null;
        setTimeout(function () {
          this.rfbInit();
        }.bind(this), 2000)
      }
      else if (msg["ping"]) {
        const msg = {
          ping: "pong"
        }
        this.props.webSock.send(JSON.stringify(msg));
      }
      else if (msg["logout"]) {
        if (this.props.onLogout){
          this.props.onLogout();
          console.log("logout");
          this.setState({redirect: true});
        }
      }
    }

    this.rfbInit(id);
  }

  connectedToServer() {
  }

  disconnectedFromServer() {
  }

  updateDesktopName() {
  }

  handleFullscreen() {
    this.setState({ isFull: true });
  }

  onSubmit = (e) =>
  {
    e.preventDefault();
    if(this.props.webSock.socketState === 1)
      this.props.webSock.send(JSON.stringify({username: this.state.userName}));
    this.setState({isLoggedIn: true});
  }

  render() {
    return (<div>
      <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({ isFull })}>
        <div className="ui">
          <div id="canvasWrap"></div>
        </div>

        {this.state.redirect ? <Redirect to="/login" /> : <></>}

        {this.state.isLoggedIn ?
          (<Menu compact style={{ position: "absolute", top: 10, right: 10, fontSize: 12 }}>
            <Dropdown simple item text='View' direction="left">
              <Dropdown.Menu >
                <Dropdown.Item text='Go fullscreen' onClick={this.handleFullscreen} />
              </Dropdown.Menu>
            </Dropdown>
          </Menu>)
          :
          (<div id='loginBox'>
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
              <Grid.Column style={{ maxWidth: 450, marginTop: 15 }}>
                <Header as='h2' color='blue' textAlign='center'>Please choose a user name</Header>
                <Form size='large' onSubmit={(event) => { this.onSubmit(event); }}>
                  <Segment stacked>
                    <Form.Input fluid icon='user'
                      iconPosition='left'
                      placeholder='User name'
                      onChange={(e) => this.setState({ userName: e.target.value })}/>
                    <Button color='blue' fluid size='large' disabled={!this.state.userName}>
                      Start
                </Button>
                  </Segment>
                </Form>
              </Grid.Column>
            </Grid>
          </div>)}
      </Fullscreen>
    </div>);
  }
}

const PresentationClientWithSocket = props => (
  <WsContext.Consumer>
    {webSock => <PresentationClient {...props} webSock={webSock} />}
  </WsContext.Consumer>
)

export default PresentationClientWithSocket;
