import React, {Component} from 'react';
import {Button, Form, Grid, Header, Segment, Divider, Container} from 'semantic-ui-react'
import API from '../components/API'
import { Link } from 'react-router-dom';

class EvaluationLogin extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      email: undefined,
      company: undefined
    };
  }

  api = new API();

  onSubmit = (e) =>
  {
    e.preventDefault();

    const id = new URLSearchParams(this.props.location.search).get("id");

    fetch(this.api.getEval(), {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        key: id,
        username: this.state.email,
        company: this.state.company ? this.state.company : ""
      })
    }).then(res => res.json())
      .then(res =>
      {
        if (res["token"] !== undefined)
        {
          var id = res["token"];
          localStorage.setItem("sessionId", id);
          if (res["isNewSession"])
          {
            localStorage.removeItem("isPrevSessionActive");
          }
          else
          {
            localStorage.setItem("isPrevSessionActive", "true");
            this.props.onSessionActive();
          }

          this.props.onLoginSuccess();
        }
        else if(res["error"] !== undefined)
        {
          console.error("Error:", res["error"]);
        }
      })
      .catch(err => console.error("Error:", err));
  }

  render()
  {
    return (
      <div id='loginBox'>
        <Grid textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
          <Grid.Column style={{maxWidth: 450, marginTop: 15}}>
            <Header as='h2' color='blue' textAlign='center'>Start ACT evaluation</Header>
            <Form size='large' onSubmit={(event) => {this.onSubmit(event);}}
              error={this.state.formError}>
              <Segment stacked>
                <Form.Input fluid icon='user'
                  iconPosition='left'
                  placeholder='E-mail address'
                  onChange={(e) => this.setState({email: e.target.value})}
                  error={this.state.emailError} />
                <Form.Input fluid icon='building'
                  iconPosition='left'
                  placeholder='Company (optional)'
                  onChange={(e) => this.setState({company: e.target.value})} />
                <Divider hidden />
                <Segment>
                  <Container>
                  By clicking on „Start Free Test Now”, you acknowledge and accept the <a href="/assets/Avionic%20Cloud%20Trainer%20(ACT)%20Trial%20Terms.pdf">ACT Trial Terms</a> of Reiser Simulation und Training GmbH. 
                  </Container>
                </Segment>
                <Divider hidden />
                <Button color='blue' fluid size='large' disabled={!this.state.email && !this.state.terms}>
                  Start Free Test Now
                </Button>
                <Divider hidden />
                <Container>
                Please note that we reserve the right to contact you and ask for your experience with the Aviation Cloud Trainer. For this purpose, we may use the personal data you provide in the registration form. If you do not want us to contact you, you may object to this use of your personal data at any time by sending an e-mail to <a href="mailto:dataprotection@reiser-st.com">dataprotection@reiser-st.com</a> without incurring transmission costs other than those according to the basic rates. Please check our <a href="/assets/Avionic%20Cloud%20Trainer%20User%20Privacy%20Notice.pdf">Privacy Policy</a> for further information. 
                </Container>
                <Divider hidden></Divider>
                <Link to="/imprint">Imprint</Link>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default EvaluationLogin;
