class API
{
    conf = {
        addr: "//" + window.location.hostname,
        websockAddr: "wss://" + window.location.hostname + "/socket/data",
        novncWsAddr: "wss://" + window.location.hostname + "/socket/vnc",
        //addr: "//localhost",
        // websockAddr: "ws://127.0.0.1/socket/data",
        // novncWsAddr: "ws://127.0.0.1/socket/vnc",

        sessionEndpoint: "/api/users/login",
        userEndpoint: "/api/user",
        regionEndpoint: "/api/region",
        evalEndpoint: "/api/eval"
    }

    connStr = this.conf.addr;

    getWebsockAddr()
    {
        return this.conf.websockAddr;
    }

    getNovncWsAddr()
    {
        return this.conf.novncWsAddr;
    }

    getSession()
    {
        return this.connStr + this.conf.sessionEndpoint;
    }

    getUser()
    {
        return this.connStr + this.conf.userEndpoint;
    }

    getRegion()
    {
        return this.connStr + this.conf.regionEndpoint;
    }

    getEval()
    {
        return this.connStr + this.conf.evalEndpoint;
    }
}

export default API;
