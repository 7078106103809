import React, {Component} from 'react';
import {Step, Loader} from 'semantic-ui-react'

class StartupFeedEvent extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      currentState: props.currentState,

      summary: props.summary,
      date: ''
    };
  }

  componentDidUpdate(oldProps)
  {
    const newProps = this.props;
    if (oldProps.currentState !== newProps.currentState)
    {
      if (newProps.currentState === 1)
        this.setState({date: new Date().toLocaleString()})
      else if (newProps.currentState === 2)
      {
        if (oldProps.currentState === 0)
          this.setState({date: new Date().toLocaleString()})
      }
      this.setState({currentState: newProps.currentState});
    }
  }

  render()
  {
    return (
      <Step active={this.state.currentState === 1}
            completed={this.state.currentState === 2}
            disabled={this.state.currentState === 0}>
        <Step.Content>
          <Step.Title>{this.state.summary}</Step.Title>
          <Step.Description>{this.state.date}</Step.Description>
        </Step.Content>
        {(this.state.currentState === 1) ?
          <Loader active inline size='small' style={{marginLeft: 15, marginRight: -10}} />
        :
        null}
      </Step>
    );
  }
}

export default StartupFeedEvent;