import React, { Component } from 'react';
import { Grid, Header, Divider, Container } from 'semantic-ui-react'

class Impress extends Component {
  render() {
    return (
      <div id='loginBox'>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450, marginTop: 15 }}>
            <Header as='h2' color='blue' textAlign='center'>Imprint</Header>
            <Header as='h4'>Reiser Simulation and Training GmbH</Header>
            <Container>Oberer Luessbach 29-31<br/>
              82335 Berg / Germany<br/>
              Phone:+49 8178 8681 - 0<br/>
              Fax:+49 8178 8681 - 30<br/>
              E-Mail:<a href="mailto:info@reiser-st.com">info@reiser-st.com</a>
              </Container>
              <Divider hidden></Divider>
            <Container>
              Commercial register: HR München, HRB 91963.<br/>
              The legal representatives of Reiser Simulation and Training GmbH are Mr. Florian Reiser and Dr. Roman Sperl.<br/>
              Value-added tax identification no.: DE 128 222 501<br/>
            </Container>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Impress;
